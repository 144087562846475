/* shop/AddProductEbook.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	text
} from "svelte/internal";

import basketStore from "./basketStore";

function create_fragment(ctx) {
	let div;
	let button;
	let t;
	let button_data_tooltiptext_value;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			button = element("button");
			t = text("E+");
			attr(button, "type", "button");
			attr(button, "class", "addProductPrintBtn tooltip tooltip-top");
			attr(button, "data-tooltiptext", button_data_tooltiptext_value = "Přidat do košíku ebook za " + /*price*/ ctx[1].toLocaleString() + " Kč");
			attr(div, "class", "addProductPrintWrap");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, button);
			append(button, t);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[3]);
				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

const quantity = 1;

function instance($$self, $$props, $$invalidate) {
	let { elm } = $$props;

	// ziskani dat z HTML
	const data = JSON.parse(elm.dataset.addProduct);

	// data ebook
	const ebook = data.ebook ? data.ebook : null;

	const price = quantity * ebook.unit_price;
	const click_handler = () => basketStore.insertProduct(ebook, quantity);

	$$self.$$set = $$props => {
		if ("elm" in $$props) $$invalidate(2, elm = $$props.elm);
	};

	return [ebook, price, elm, click_handler];
}

class AddProductEbook extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { elm: 2 });
	}
}

export default AddProductEbook;