import { writable, get } from "svelte/store";
import { derived } from "svelte/store";

const storageKey = "___order_kher___";

const emptyData = {
    customer_type: null,
    name_and_surname: null,
    email: null,
    phone: null,
    address_street: null,
    address_city: null,
    address_zip: null,
    order_description: null,
    company_name: null,
    company_id: null,
    delivery_address_street: null,
    delivery_address_city: null,
    delivery_address_zip: null,
    terms_and_conditions_consent: false,
    sending_newsletters_consent: false,
    payment_title: null,
    payment_method_type: null,
    price_payment: null,

    // doprava
    delivery_id: null,
    delivery_type: null,
    delivery_title: null,
    delivery_data: {},
    price_delivery: null,

};

const loadFromStorage = () => {
    let json = localStorage.getItem(storageKey);
    let data = json ? JSON.parse(json) : emptyData;
    return data;
};

const saveToStorage = (data) => {
    localStorage.setItem(storageKey, JSON.stringify(data));
};

/* store - BEGIN */
const createStore = () => {
    const store = writable(loadFromStorage());

    // "VEREJNE" metody - BEGIN
    const setProp = (name, value) => {
        store.update(current => {
            current[name] = value;
            return { ...current };
        });
        persist();
        return validateProp(name, value);
    };

    const setDelivery = (delivery_id, delivery_type, delivery_title = null, price_delivery = null, delivery_data = {}) => {
        setProp("delivery_id", delivery_id);
        setProp("delivery_type", delivery_type);
        setProp("delivery_title", delivery_title);
        setProp("price_delivery", price_delivery);
        setProp("delivery_data", delivery_data);
    };

    const validateProp = (name, value) => {
        const current = get(store);

        // povinne polozky
        const required = [
            'customer_type', 'name_and_surname', 'email', 'phone',
            'address_street', 'address_city', 'address_zip',
            'terms_and_conditions_consent', 'delivery_id', 'delivery_type', 'delivery_title', 'payment_title'];
        if (required.includes(name) && (!value || value == '')) {
            return false;
        }

        // polozky ktere mohou byt nula ale nesmi byt null
        const notNull = ['price_payment', 'price_delivery']
        if (notNull.includes(name) && value === null) {
            return false;
        }

        // customer_type
        const allowed_customer_types = ['COMPANY', 'PERSONAL'];
        if (name == 'customer_type' && !allowed_customer_types.includes(value)) {
            return false;
        }

        // povinne polozky pro firmu
        if (current.customer_type == 'COMPANY') {
            if (name == 'company_name' && !value) {
                return false;
            }
            if (name == 'company_id' && !value) {
                return false;
            }
        }

        // souhlas s VOP
        if (name == 'terms_and_conditions_consent' && value !== true) {
            return false;
        }

        // vse OK
        return true;

    };

    const clean = () => {
        store.update(current => { return emptyData });
        persist();
    };

    const isReadyForSubmit = () => {
        const data = get(store);
        for (const key in data) {
            if (!validateProp(key, data[key])) {
                return false;
            }
        }
        return true;
    };
    // "VEREJNE" metody - END

    // "NEVEREJNE" metody - BEGIN
    const persist = () => {
        saveToStorage(get(store));
    };
    // "NEVEREJNE" metody - END

    // vraceni vytvoreneho store
    return {
        subscribe: store.subscribe,
        unsubscribe: store.unsubscribe,
        setProp, setDelivery, validateProp,
        isReadyForSubmit,
    };

};
/* store - END */

// export store
const orderStore = createStore();
export default orderStore;


// isOrderReadyForSubmit
export const isOrderReadyForSubmit = derived(orderStore, () => orderStore.isReadyForSubmit());
