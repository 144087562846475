/* shop/components/delivery/ZasilkovnaDelivery.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

import { onMount } from "svelte";
import orderStore from "./../../orderStore";

function create_if_block_3(ctx) {
	let small;
	let t_value = /*deliveryItem*/ ctx[0].subtitle + "";
	let t;

	return {
		c() {
			small = element("small");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, small, anchor);
			append(small, t);
		},
		p(ctx, dirty) {
			if (dirty & /*deliveryItem*/ 1 && t_value !== (t_value = /*deliveryItem*/ ctx[0].subtitle + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(small);
		}
	};
}

// (45:4) {#if deliveryItem.price}
function create_if_block_2(ctx) {
	let t0;
	let t1_value = /*deliveryItem*/ ctx[0].price + "";
	let t1;
	let t2;

	return {
		c() {
			t0 = text(": ");
			t1 = text(t1_value);
			t2 = text(" Kč");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
			insert(target, t2, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*deliveryItem*/ 1 && t1_value !== (t1_value = /*deliveryItem*/ ctx[0].price + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
			if (detaching) detach(t2);
		}
	};
}

// (49:4) {#if $orderStore.delivery_id == deliveryItem.id}
function create_if_block_1(ctx) {
	let div;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			button = element("button");
			button.textContent = "Vybrat místo";
			attr(button, "type", "button");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, button);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler_1*/ ctx[5]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

// (59:4) {#if $orderStore.delivery_id == deliveryItem.id && $orderStore.delivery_data?.name}
function create_if_block(ctx) {
	let div;
	let t0;
	let t1_value = /*$orderStore*/ ctx[2].delivery_data?.name + "";
	let t1;

	return {
		c() {
			div = element("div");
			t0 = text("Vybráno: ");
			t1 = text(t1_value);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t0);
			append(div, t1);
		},
		p(ctx, dirty) {
			if (dirty & /*$orderStore*/ 4 && t1_value !== (t1_value = /*$orderStore*/ ctx[2].delivery_data?.name + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let input;
	let input_id_value;
	let input_checked_value;
	let t0;
	let label;
	let t1_value = /*deliveryItem*/ ctx[0].title + "";
	let t1;
	let t2;
	let t3;
	let t4;
	let t5;
	let label_for_value;
	let mounted;
	let dispose;
	let if_block0 = /*deliveryItem*/ ctx[0].subtitle && create_if_block_3(ctx);
	let if_block1 = /*deliveryItem*/ ctx[0].price && create_if_block_2(ctx);
	let if_block2 = /*$orderStore*/ ctx[2].delivery_id == /*deliveryItem*/ ctx[0].id && create_if_block_1(ctx);
	let if_block3 = /*$orderStore*/ ctx[2].delivery_id == /*deliveryItem*/ ctx[0].id && /*$orderStore*/ ctx[2].delivery_data?.name && create_if_block(ctx);

	return {
		c() {
			input = element("input");
			t0 = space();
			label = element("label");
			t1 = text(t1_value);
			t2 = space();
			if (if_block0) if_block0.c();
			t3 = space();
			if (if_block1) if_block1.c();
			t4 = space();
			if (if_block2) if_block2.c();
			t5 = space();
			if (if_block3) if_block3.c();
			attr(input, "type", "radio");
			attr(input, "class", "radok");
			attr(input, "id", input_id_value = "delivery_" + /*deliveryItem*/ ctx[0].id);
			input.checked = input_checked_value = /*$orderStore*/ ctx[2].delivery_title == /*deliveryItem*/ ctx[0].title;
			attr(label, "for", label_for_value = "delivery_" + /*deliveryItem*/ ctx[0].id);
		},
		m(target, anchor) {
			insert(target, input, anchor);
			insert(target, t0, anchor);
			insert(target, label, anchor);
			append(label, t1);
			append(label, t2);
			if (if_block0) if_block0.m(label, null);
			append(label, t3);
			if (if_block1) if_block1.m(label, null);
			append(label, t4);
			if (if_block2) if_block2.m(label, null);
			append(label, t5);
			if (if_block3) if_block3.m(label, null);

			if (!mounted) {
				dispose = listen(input, "click", /*click_handler*/ ctx[4]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*deliveryItem*/ 1 && input_id_value !== (input_id_value = "delivery_" + /*deliveryItem*/ ctx[0].id)) {
				attr(input, "id", input_id_value);
			}

			if (dirty & /*$orderStore, deliveryItem*/ 5 && input_checked_value !== (input_checked_value = /*$orderStore*/ ctx[2].delivery_title == /*deliveryItem*/ ctx[0].title)) {
				input.checked = input_checked_value;
			}

			if (dirty & /*deliveryItem*/ 1 && t1_value !== (t1_value = /*deliveryItem*/ ctx[0].title + "")) set_data(t1, t1_value);

			if (/*deliveryItem*/ ctx[0].subtitle) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					if_block0.m(label, t3);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*deliveryItem*/ ctx[0].price) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					if_block1.m(label, t4);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*$orderStore*/ ctx[2].delivery_id == /*deliveryItem*/ ctx[0].id) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_1(ctx);
					if_block2.c();
					if_block2.m(label, t5);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			if (/*$orderStore*/ ctx[2].delivery_id == /*deliveryItem*/ ctx[0].id && /*$orderStore*/ ctx[2].delivery_data?.name) {
				if (if_block3) {
					if_block3.p(ctx, dirty);
				} else {
					if_block3 = create_if_block(ctx);
					if_block3.c();
					if_block3.m(label, null);
				}
			} else if (if_block3) {
				if_block3.d(1);
				if_block3 = null;
			}

			if (dirty & /*deliveryItem*/ 1 && label_for_value !== (label_for_value = "delivery_" + /*deliveryItem*/ ctx[0].id)) {
				attr(label, "for", label_for_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(input);
			if (detaching) detach(t0);
			if (detaching) detach(label);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			mounted = false;
			dispose();
		}
	};
}

const type = "zasilkovna";

function instance($$self, $$props, $$invalidate) {
	let $orderStore;
	component_subscribe($$self, orderStore, $$value => $$invalidate(2, $orderStore = $$value));
	let { deliveryItem } = $$props;
	let widget = undefined;

	onMount(() => {
		$$invalidate(1, widget = Packeta != undefined ? Packeta.Widget : undefined);
	});

	const setDeliveryPoint = point => {
		const delivery_data = {
			id: point.id,
			name: point.name,
			url: point.url,
			subtitleHTML: createSubtitleHTML(point)
		};

		orderStore.setDelivery(deliveryItem.id, type, deliveryItem.title, deliveryItem.price, delivery_data);
	};

	const createSubtitleHTML = point => {
		let html = "<a href=\"" + point.url + "\" target=\"_blank\">";
		html += point.name;
		html += "</a>";
		return html;
	};

	const click_handler = () => {
		orderStore.setDelivery(deliveryItem.id, type, deliveryItem.title, deliveryItem.price);
	};

	const click_handler_1 = () => {
		widget && widget.pick(deliveryItem.configuration.apikey, setDeliveryPoint, deliveryItem.configuration.options);
	};

	$$self.$$set = $$props => {
		if ("deliveryItem" in $$props) $$invalidate(0, deliveryItem = $$props.deliveryItem);
	};

	return [
		deliveryItem,
		widget,
		$orderStore,
		setDeliveryPoint,
		click_handler,
		click_handler_1
	];
}

class ZasilkovnaDelivery extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { deliveryItem: 0 });
	}
}

export default ZasilkovnaDelivery;