/* shop/MiniBasket.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

import { basketPrice, basketItemsCount } from "./basketStore";
import basketImage from "./basket.svg";

function create_if_block(ctx) {
	let span;
	let t;

	return {
		c() {
			span = element("span");
			t = text(/*$basketItemsCount*/ ctx[0]);
			attr(span, "class", "header--basketLabel");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t);
		},
		p(ctx, dirty) {
			if (dirty & /*$basketItemsCount*/ 1) set_data(t, /*$basketItemsCount*/ ctx[0]);
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

function create_fragment(ctx) {
	let nav;
	let a;
	let img;
	let img_src_value;
	let t;
	let if_block = /*$basketItemsCount*/ ctx[0] > 0 && create_if_block(ctx);

	return {
		c() {
			nav = element("nav");
			a = element("a");
			img = element("img");
			t = space();
			if (if_block) if_block.c();
			if (img.src !== (img_src_value = basketImage)) attr(img, "src", img_src_value);
			attr(img, "alt", "Košík");
			attr(a, "href", /*url*/ ctx[1]);
			attr(nav, "class", "header--basket");
		},
		m(target, anchor) {
			insert(target, nav, anchor);
			append(nav, a);
			append(a, img);
			append(a, t);
			if (if_block) if_block.m(a, null);
		},
		p(ctx, [dirty]) {
			if (/*$basketItemsCount*/ ctx[0] > 0) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(a, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(nav);
			if (if_block) if_block.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $basketItemsCount;
	component_subscribe($$self, basketItemsCount, $$value => $$invalidate(0, $basketItemsCount = $$value));
	const dataElm = document.getElementById("miniBasket");
	const url = dataElm.dataset.url;
	return [$basketItemsCount, url];
}

class MiniBasket extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default MiniBasket;