/* eslint-disable prettier/prettier */
import { writable, get } from "svelte/store";
import { derived } from 'svelte/store';

const storageKey = "___basket_kher___";

const emptyData = { items: [] };

const loadFromStorage = () => {
    let json = localStorage.getItem(storageKey);
    let data = json ? JSON.parse(json) : emptyData;
    return data;
};

const saveToStorage = (data) => {
    localStorage.setItem(storageKey, JSON.stringify(data));
};

/* store - BEGIN */
const createStore = () => {
    const store = writable(loadFromStorage());

    const initDiscount = (quantityThreshold, unitDiscount, startDate, endDate) => {
        store.update(current => {
            current = {...current, quantityThreshold: quantityThreshold, unitDiscount: unitDiscount, startDate: startDate, endDate: endDate}
            current = updateQuantityDiscount(current)
            return current;
        })
    }

    // "VEREJNE" metody - BEGIN
    const insertProduct = (productData, quantity) => {
        const item = createItem(productData, quantity);
        store.update(current => {
            current.items.push(item);
            current = updateQuantityDiscount(current);
            return {...current};
        });
        
        persist();
        alert("Položka byla přidána do košíku");
    };

    const removeProduct = (book, book_type) => {
        store.update(current => {
            current.items = current.items.filter(
                item => !(item.book == book && item.book_type == book_type));
            current = updateQuantityDiscount(current);
            return current;
        });
        persist();
    };

    const setProductQuantity = (book, book_type, quantity) => {
        store.update(current => {
            current.items = current.items.map(item => {
                if (item.book == book && item.book_type == book_type) {
                    item.quantity = quantity;
                    item = setFullPrice(item);
                }
                return item;
            });
            current = updateQuantityDiscount(current);
            return current;
        });
        persist();
    };

    const clean = () => {
        store.update(current => { return emptyData });
        persist();
    };
    // "VEREJNE" metody - END

    // "NEVEREJNE" metody - BEGIN
    const createItem = (productData, quantity) => {
        let item = {
            book: productData.book,
            book_type: productData.book_type,
            title: productData.title,
            description: productData.description,
            unit_price: productData.unit_price,
            quantity: quantity,
            quantity_discount: 0
        };
        item = setFullPrice(item);
        return item;
    };

    const updateQuantityDiscount = (current) => {
        let basketQuantity = current.items.map(item => Number(item.quantity)).reduce((a, b) => a + b, 0);
        const now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Prague"}));
        let discount = 0;

        if(basketQuantity >= current.quantityThreshold && 
            now >= current.startDate && now < current.endDate) 
        {
            discount = current.unitDiscount ? current.unitDiscount : 0;
        }

        current.items = current.items.map(item => {
            item.quantity_discount = discount * item.quantity;
            return item
        });
        return {...current};
    };

    const setFullPrice = (item) => {
        item.full_price = item.quantity * item.unit_price;
        return item;
    };

    const persist = () => {
        saveToStorage(get(store));
    };
    // "NEVEREJNE" metody - END

    // vraceni vytvoreneho store
    return {
        subscribe: store.subscribe,
        unsubscribe: store.unsubscribe,
        insertProduct,
        removeProduct,
        setProductQuantity,
        clean,
        initDiscount
    };

};
/* store - END */

// export store
const basketStore = createStore();
export default basketStore;


// basketPrice
export const basketPrice = derived(basketStore, $basketStore => {
    let price = 0;
    $basketStore.items.forEach(item => price += item.full_price - item.quantity_discount);
    return price;
});

const _basketQuantity = (basketStore) => basketStore.items.map(item => Number(item.quantity)).reduce((a, b) => a + b, 0)
const _basketQuantityDiscount = (basketStore) => basketStore.items.map(item => Number(item.quantity_discount)).reduce((a, b) => a + b, 0)

// TODO: basket Quantity
export const basketQuantity = derived(basketStore, $basketStore => {
    return _basketQuantity($basketStore)
});

export const basketDiscount = derived(basketStore, $basketStore => {
    return _basketQuantityDiscount($basketStore);
});

export const isDiscountActive = derived(basketStore, $basketStore => {
    // Using Prague as UTC+2 timezone
    const now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Prague"}));
    return (now >= $basketStore.startDate && now < $basketStore.endDate);
});

// basketItemsCount
export const basketItemsCount = derived(basketStore, $basketStore => {
    return $basketStore.items.length;
});