import Cookies from 'js-cookie';

const URL = "/api/shop/orders/"

const submitOrder = (data, success_callback, error_callback) => {

    const xhr = new XMLHttpRequest();
    xhr.open("POST", URL, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("X-CSRFToken", Cookies.get('csrftoken'));

    // chyba pri odeslani
    xhr.addEventListener("error", () => {
        error_callback(xhr);
    });

    // zpracovani response
    xhr.addEventListener("load", () => {

        // v poradku
        if (xhr.status == 200) {
            success_callback(xhr);

        // chyba
        } else {
            error_callback(xhr);
        }

    });

    // odeslani pozadavku
    xhr.send(JSON.stringify(data));

}

export default submitOrder;
