import '@babel/polyfill';
import "nodelist-foreach-polyfill";
import MiniBasket from './MiniBasket.svelte';
import AddProduct from './AddProduct.svelte';
import AddProductPrint from './AddProductPrint.svelte';
import AddProductEbook from './AddProductEbook.svelte';
import BasketAndOrder from './BasketAndOrder.svelte';
import OrderEbookDownloader from './OrderEbookDownloader.svelte';


// minibasket
const miniBasketElm = document.getElementById('miniBasket');
if (miniBasketElm) {
    new MiniBasket({
        target: miniBasketElm,
        props: {}
    });
}

// addproduct
const addProductElm = document.getElementById('addProduct');
if (addProductElm) {
    new AddProduct({
        target: addProductElm,
        props: {}
    });
}

// addproductprint
const addProductPrintElms = document.querySelectorAll(".addProductPrint");
if (addProductPrintElms) {
    addProductPrintElms.forEach(item => {
        new AddProductPrint({
            target: item,
            props: {elm: item}
        });
    });
}

// addproductebook
const addProductEbookElms = document.querySelectorAll(".addProductEbook");
if (addProductEbookElms) {
    addProductEbookElms.forEach(item => {
        new AddProductEbook({
            target: item,
            props: {elm: item}
        });
    });
}

// basket
const basketAndOrderElm = document.getElementById('basketAndOrder');
if (basketAndOrderElm) {
    new BasketAndOrder({
        target: basketAndOrderElm,
        props: {}
    });
}

// order ebook downloader
const orderEbookDownloaderElm = document.getElementById('orderEbookDownloader');
if (orderEbookDownloaderElm) {
    new OrderEbookDownloader({
        target: orderEbookDownloaderElm,
        props: {}
    });
}
