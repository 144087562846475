/* shop/components/Order.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import OrderPersonal from "./OrderPersonal.svelte";
import OrderCompany from "./OrderCompany.svelte";
import StandardDelivery from "./delivery/StandardDelivery.svelte";
import ZasilkovnaDelivery from "./delivery/ZasilkovnaDelivery.svelte";
import orderStore from "./../orderStore";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[8] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[11] = list[i];
	return child_ctx;
}

// (28:4) {#if $orderStore.customer_type == 'PERSONAL'}
function create_if_block_4(ctx) {
	let orderpersonal;
	let current;
	orderpersonal = new OrderPersonal({});

	return {
		c() {
			create_component(orderpersonal.$$.fragment);
		},
		m(target, anchor) {
			mount_component(orderpersonal, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(orderpersonal.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(orderpersonal.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(orderpersonal, detaching);
		}
	};
}

// (32:4) {#if $orderStore.customer_type == 'COMPANY'}
function create_if_block_3(ctx) {
	let ordercompany;
	let current;
	ordercompany = new OrderCompany({});

	return {
		c() {
			create_component(ordercompany.$$.fragment);
		},
		m(target, anchor) {
			mount_component(ordercompany, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(ordercompany.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(ordercompany.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(ordercompany, detaching);
		}
	};
}

// (68:57) 
function create_if_block_2(ctx) {
	let zasilkovnadelivery;
	let current;

	zasilkovnadelivery = new ZasilkovnaDelivery({
			props: { deliveryItem: /*deliveryItem*/ ctx[11] }
		});

	return {
		c() {
			create_component(zasilkovnadelivery.$$.fragment);
		},
		m(target, anchor) {
			mount_component(zasilkovnadelivery, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const zasilkovnadelivery_changes = {};
			if (dirty & /*delivery*/ 1) zasilkovnadelivery_changes.deliveryItem = /*deliveryItem*/ ctx[11];
			zasilkovnadelivery.$set(zasilkovnadelivery_changes);
		},
		i(local) {
			if (current) return;
			transition_in(zasilkovnadelivery.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(zasilkovnadelivery.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(zasilkovnadelivery, detaching);
		}
	};
}

// (66:8) {#if deliveryItem.type_slug == "standard"}
function create_if_block_1(ctx) {
	let standarddelivery;
	let current;

	standarddelivery = new StandardDelivery({
			props: { deliveryItem: /*deliveryItem*/ ctx[11] }
		});

	return {
		c() {
			create_component(standarddelivery.$$.fragment);
		},
		m(target, anchor) {
			mount_component(standarddelivery, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const standarddelivery_changes = {};
			if (dirty & /*delivery*/ 1) standarddelivery_changes.deliveryItem = /*deliveryItem*/ ctx[11];
			standarddelivery.$set(standarddelivery_changes);
		},
		i(local) {
			if (current) return;
			transition_in(standarddelivery.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(standarddelivery.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(standarddelivery, detaching);
		}
	};
}

// (65:4) {#each delivery as deliveryItem}
function create_each_block_1(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block_1, create_if_block_2];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*deliveryItem*/ ctx[11].type_slug == "standard") return 0;
		if (/*deliveryItem*/ ctx[11].type_slug == "zasilkovna") return 1;
		return -1;
	}

	if (~(current_block_type_index = select_block_type(ctx, -1))) {
		if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	}

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (~current_block_type_index) {
				if_blocks[current_block_type_index].m(target, anchor);
			}

			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if (~current_block_type_index) {
					if_blocks[current_block_type_index].p(ctx, dirty);
				}
			} else {
				if (if_block) {
					group_outros();

					transition_out(if_blocks[previous_block_index], 1, 1, () => {
						if_blocks[previous_block_index] = null;
					});

					check_outros();
				}

				if (~current_block_type_index) {
					if_block = if_blocks[current_block_type_index];

					if (!if_block) {
						if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
						if_block.c();
					} else {
						if_block.p(ctx, dirty);
					}

					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				} else {
					if_block = null;
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (~current_block_type_index) {
				if_blocks[current_block_type_index].d(detaching);
			}

			if (detaching) detach(if_block_anchor);
		}
	};
}

// (92:12) {#if paymentMethod.price}
function create_if_block(ctx) {
	let t0;
	let t1_value = /*paymentMethod*/ ctx[8].price + "";
	let t1;
	let t2;

	return {
		c() {
			t0 = text(": ");
			t1 = text(t1_value);
			t2 = text(" Kč");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
			insert(target, t2, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*paymentMethods*/ 2 && t1_value !== (t1_value = /*paymentMethod*/ ctx[8].price + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
			if (detaching) detach(t2);
		}
	};
}

// (79:4) {#each paymentMethods as paymentMethod}
function create_each_block(ctx) {
	let input;
	let input_id_value;
	let input_checked_value;
	let t0;
	let label;
	let t1_value = /*paymentMethod*/ ctx[8].title + "";
	let t1;
	let t2;
	let t3;
	let label_for_value;
	let mounted;
	let dispose;

	function click_handler_2() {
		return /*click_handler_2*/ ctx[5](/*paymentMethod*/ ctx[8]);
	}

	let if_block = /*paymentMethod*/ ctx[8].price && create_if_block(ctx);

	return {
		c() {
			input = element("input");
			t0 = space();
			label = element("label");
			t1 = text(t1_value);
			t2 = space();
			if (if_block) if_block.c();
			t3 = space();
			attr(input, "type", "radio");
			attr(input, "class", "radok");
			attr(input, "id", input_id_value = "payment_method_" + /*paymentMethod*/ ctx[8].id);
			input.checked = input_checked_value = /*$orderStore*/ ctx[2].payment_title == /*paymentMethod*/ ctx[8].title;
			attr(label, "for", label_for_value = "payment_method_" + /*paymentMethod*/ ctx[8].id);
		},
		m(target, anchor) {
			insert(target, input, anchor);
			insert(target, t0, anchor);
			insert(target, label, anchor);
			append(label, t1);
			append(label, t2);
			if (if_block) if_block.m(label, null);
			append(label, t3);

			if (!mounted) {
				dispose = listen(input, "click", click_handler_2);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*paymentMethods*/ 2 && input_id_value !== (input_id_value = "payment_method_" + /*paymentMethod*/ ctx[8].id)) {
				attr(input, "id", input_id_value);
			}

			if (dirty & /*$orderStore, paymentMethods*/ 6 && input_checked_value !== (input_checked_value = /*$orderStore*/ ctx[2].payment_title == /*paymentMethod*/ ctx[8].title)) {
				input.checked = input_checked_value;
			}

			if (dirty & /*paymentMethods*/ 2 && t1_value !== (t1_value = /*paymentMethod*/ ctx[8].title + "")) set_data(t1, t1_value);

			if (/*paymentMethod*/ ctx[8].price) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(label, t3);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*paymentMethods*/ 2 && label_for_value !== (label_for_value = "payment_method_" + /*paymentMethod*/ ctx[8].id)) {
				attr(label, "for", label_for_value);
			}
		},
		d(detaching) {
			if (detaching) detach(input);
			if (detaching) detach(t0);
			if (detaching) detach(label);
			if (if_block) if_block.d();
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let h1;
	let t1;
	let div0;
	let t3;
	let div2;
	let section0;
	let t4;
	let t5;
	let div1;
	let section1;
	let h20;
	let t7;
	let input0;
	let input0_checked_value;
	let t8;
	let label0;
	let t10;
	let input1;
	let input1_checked_value;
	let t11;
	let label1;
	let t13;
	let section2;
	let h21;
	let t15;
	let t16;
	let section3;
	let h22;
	let t18;
	let t19;
	let div3;
	let input2;
	let input2_checked_value;
	let t20;
	let label2;
	let t23;
	let div4;
	let input3;
	let input3_checked_value;
	let t24;
	let label3;
	let current;
	let mounted;
	let dispose;
	let if_block0 = /*$orderStore*/ ctx[2].customer_type == "PERSONAL" && create_if_block_4(ctx);
	let if_block1 = /*$orderStore*/ ctx[2].customer_type == "COMPANY" && create_if_block_3(ctx);
	let each_value_1 = /*delivery*/ ctx[0];
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const out = i => transition_out(each_blocks_1[i], 1, 1, () => {
		each_blocks_1[i] = null;
	});

	let each_value = /*paymentMethods*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Objednávka";
			t1 = space();
			div0 = element("div");
			div0.textContent = " ";
			t3 = space();
			div2 = element("div");
			section0 = element("section");
			if (if_block0) if_block0.c();
			t4 = space();
			if (if_block1) if_block1.c();
			t5 = space();
			div1 = element("div");
			section1 = element("section");
			h20 = element("h2");
			h20.textContent = "Subjekt";
			t7 = space();
			input0 = element("input");
			t8 = space();
			label0 = element("label");
			label0.textContent = "Soukromá osoba";
			t10 = space();
			input1 = element("input");
			t11 = space();
			label1 = element("label");
			label1.textContent = "Firma";
			t13 = space();
			section2 = element("section");
			h21 = element("h2");
			h21.textContent = "Doprava";
			t15 = space();

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t16 = space();
			section3 = element("section");
			h22 = element("h2");
			h22.textContent = "Platba";
			t18 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t19 = space();
			div3 = element("div");
			input2 = element("input");
			t20 = space();
			label2 = element("label");
			label2.innerHTML = `Souhlasím se <a href="/obchodni-podminky/" target="_blank">všeobecnými obchodními podmínkami a zpracováním osobních údajů</a>`;
			t23 = space();
			div4 = element("div");
			input3 = element("input");
			t24 = space();
			label3 = element("label");
			label3.textContent = "Souhlasím s uložením svých kontaktních údajů do databáze KHER pro účely občasného informování o novinkách";
			attr(div0, "class", "basketInfoEmpty");
			attr(section0, "class", "basketOrderPerson");
			attr(input0, "type", "radio");
			attr(input0, "class", "radok");
			attr(input0, "id", "customer_type_personal");
			input0.checked = input0_checked_value = /*$orderStore*/ ctx[2].customer_type == "PERSONAL";
			attr(label0, "for", "customer_type_personal");
			attr(input1, "type", "radio");
			attr(input1, "class", "radok");
			attr(input1, "id", "customer_type_company");
			input1.checked = input1_checked_value = /*$orderStore*/ ctx[2].customer_type == "COMPANY";
			attr(label1, "for", "customer_type_company");
			attr(div1, "class", "basketOrderPayment");
			attr(div2, "class", "basketOrder");
			attr(input2, "type", "checkbox");
			attr(input2, "class", "checkok");
			attr(input2, "id", "terms_and_conditions_consent");
			input2.required = true;
			input2.checked = input2_checked_value = /*$orderStore*/ ctx[2].terms_and_conditions_consent;
			input2.value = "true";
			attr(label2, "for", "terms_and_conditions_consent");
			attr(div3, "class", "basketButtonWrap");
			attr(input3, "type", "checkbox");
			attr(input3, "class", "checkok");
			attr(input3, "id", "sending_newsletters_consent");
			input3.checked = input3_checked_value = /*$orderStore*/ ctx[2].sending_newsletters_consent;
			input3.value = "true";
			attr(label3, "for", "sending_newsletters_consent");
			attr(div4, "class", "basketButtonWrap");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, div0, anchor);
			insert(target, t3, anchor);
			insert(target, div2, anchor);
			append(div2, section0);
			if (if_block0) if_block0.m(section0, null);
			append(section0, t4);
			if (if_block1) if_block1.m(section0, null);
			append(div2, t5);
			append(div2, div1);
			append(div1, section1);
			append(section1, h20);
			append(section1, t7);
			append(section1, input0);
			append(section1, t8);
			append(section1, label0);
			append(section1, t10);
			append(section1, input1);
			append(section1, t11);
			append(section1, label1);
			append(div1, t13);
			append(div1, section2);
			append(section2, h21);
			append(section2, t15);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(section2, null);
			}

			append(div1, t16);
			append(div1, section3);
			append(section3, h22);
			append(section3, t18);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(section3, null);
			}

			insert(target, t19, anchor);
			insert(target, div3, anchor);
			append(div3, input2);
			append(div3, t20);
			append(div3, label2);
			insert(target, t23, anchor);
			insert(target, div4, anchor);
			append(div4, input3);
			append(div4, t24);
			append(div4, label3);
			current = true;

			if (!mounted) {
				dispose = [
					listen(input0, "click", /*click_handler*/ ctx[3]),
					listen(input1, "click", /*click_handler_1*/ ctx[4]),
					listen(input2, "change", /*change_handler*/ ctx[6]),
					listen(input3, "change", /*change_handler_1*/ ctx[7])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*$orderStore*/ ctx[2].customer_type == "PERSONAL") {
				if (if_block0) {
					if (dirty & /*$orderStore*/ 4) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_4(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(section0, t4);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*$orderStore*/ ctx[2].customer_type == "COMPANY") {
				if (if_block1) {
					if (dirty & /*$orderStore*/ 4) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_3(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(section0, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (!current || dirty & /*$orderStore*/ 4 && input0_checked_value !== (input0_checked_value = /*$orderStore*/ ctx[2].customer_type == "PERSONAL")) {
				input0.checked = input0_checked_value;
			}

			if (!current || dirty & /*$orderStore*/ 4 && input1_checked_value !== (input1_checked_value = /*$orderStore*/ ctx[2].customer_type == "COMPANY")) {
				input1.checked = input1_checked_value;
			}

			if (dirty & /*delivery*/ 1) {
				each_value_1 = /*delivery*/ ctx[0];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
						transition_in(each_blocks_1[i], 1);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						transition_in(each_blocks_1[i], 1);
						each_blocks_1[i].m(section2, null);
					}
				}

				group_outros();

				for (i = each_value_1.length; i < each_blocks_1.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (dirty & /*paymentMethods, $orderStore, orderStore*/ 6) {
				each_value = /*paymentMethods*/ ctx[1];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(section3, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (!current || dirty & /*$orderStore*/ 4 && input2_checked_value !== (input2_checked_value = /*$orderStore*/ ctx[2].terms_and_conditions_consent)) {
				input2.checked = input2_checked_value;
			}

			if (!current || dirty & /*$orderStore*/ 4 && input3_checked_value !== (input3_checked_value = /*$orderStore*/ ctx[2].sending_newsletters_consent)) {
				input3.checked = input3_checked_value;
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks_1[i]);
			}

			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			each_blocks_1 = each_blocks_1.filter(Boolean);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				transition_out(each_blocks_1[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(div0);
			if (detaching) detach(t3);
			if (detaching) detach(div2);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			destroy_each(each_blocks_1, detaching);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t19);
			if (detaching) detach(div3);
			if (detaching) detach(t23);
			if (detaching) detach(div4);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $orderStore;
	component_subscribe($$self, orderStore, $$value => $$invalidate(2, $orderStore = $$value));
	let { delivery } = $$props;
	let { paymentMethods } = $$props;
	const click_handler = () => orderStore.setProp("customer_type", "PERSONAL");
	const click_handler_1 = () => orderStore.setProp("customer_type", "COMPANY");

	const click_handler_2 = paymentMethod => {
		orderStore.setProp("payment_title", paymentMethod.title);
		orderStore.setProp("payment_method_type", paymentMethod.type);
		orderStore.setProp("price_payment", paymentMethod.price);
	};

	const change_handler = e => {
		orderStore.setProp("terms_and_conditions_consent", e.target.checked);
	};

	const change_handler_1 = e => {
		orderStore.setProp("sending_newsletters_consent", e.target.checked);
	};

	$$self.$$set = $$props => {
		if ("delivery" in $$props) $$invalidate(0, delivery = $$props.delivery);
		if ("paymentMethods" in $$props) $$invalidate(1, paymentMethods = $$props.paymentMethods);
	};

	return [
		delivery,
		paymentMethods,
		$orderStore,
		click_handler,
		click_handler_1,
		click_handler_2,
		change_handler,
		change_handler_1
	];
}

class Order extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { delivery: 0, paymentMethods: 1 });
	}
}

export default Order;