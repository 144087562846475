/* shop/components/ThankYou.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	empty,
	init,
	insert,
	noop,
	safe_not_equal,
	space,
	text
} from "svelte/internal";

function create_if_block(ctx) {
	let div;
	let a;
	let t;
	let a_href_value;

	return {
		c() {
			div = element("div");
			a = element("a");
			t = text("Zaplatit");
			attr(a, "href", a_href_value = /*order*/ ctx[0].online_payment_url);
			attr(a, "class", "basketButton");
			attr(div, "class", "basketButtonWrap");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, a);
			append(a, t);
		},
		p(ctx, dirty) {
			if (dirty & /*order*/ 1 && a_href_value !== (a_href_value = /*order*/ ctx[0].online_payment_url)) {
				attr(a, "href", a_href_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let h1;
	let t2;
	let if_block_anchor;
	let if_block = /*order*/ ctx[0] && /*order*/ ctx[0].online_payment_url && create_if_block(ctx);

	return {
		c() {
			h1 = element("h1");
			h1.innerHTML = `Děkujeme<br/>Vaše obejdnávka byla uložena.`;
			t2 = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t2, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (/*order*/ ctx[0] && /*order*/ ctx[0].online_payment_url) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t2);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { order } = $$props;

	$$self.$$set = $$props => {
		if ("order" in $$props) $$invalidate(0, order = $$props.order);
	};

	return [order];
}

class ThankYou extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { order: 0 });
	}
}

export default ThankYou;