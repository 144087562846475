/* shop/components/OrderCompany.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	space
} from "svelte/internal";

import orderStore from "./../orderStore";

function create_fragment(ctx) {
	let p0;
	let label0;
	let t1;
	let input0;
	let input0_value_value;
	let t2;
	let p1;
	let label1;
	let t4;
	let input1;
	let input1_value_value;
	let t5;
	let p2;
	let label2;
	let t7;
	let input2;
	let input2_value_value;
	let t8;
	let p3;
	let label3;
	let t10;
	let input3;
	let input3_value_value;
	let t11;
	let p4;
	let label4;
	let t13;
	let input4;
	let input4_value_value;
	let t14;
	let h30;
	let t16;
	let p5;
	let label5;
	let t18;
	let input5;
	let input5_value_value;
	let t19;
	let p6;
	let label6;
	let t21;
	let input6;
	let input6_value_value;
	let t22;
	let p7;
	let label7;
	let t24;
	let input7;
	let input7_value_value;
	let t25;
	let h31;
	let t28;
	let p8;
	let label8;
	let t30;
	let input8;
	let input8_value_value;
	let t31;
	let p9;
	let label9;
	let t33;
	let input9;
	let input9_value_value;
	let t34;
	let p10;
	let label10;
	let t36;
	let input10;
	let input10_value_value;
	let t37;
	let p11;
	let label11;
	let t39;
	let textarea;
	let textarea_value_value;
	let mounted;
	let dispose;

	return {
		c() {
			p0 = element("p");
			label0 = element("label");
			label0.textContent = "Název firmy";
			t1 = space();
			input0 = element("input");
			t2 = space();
			p1 = element("p");
			label1 = element("label");
			label1.textContent = "IČ";
			t4 = space();
			input1 = element("input");
			t5 = space();
			p2 = element("p");
			label2 = element("label");
			label2.textContent = "Kontaktní osoba";
			t7 = space();
			input2 = element("input");
			t8 = space();
			p3 = element("p");
			label3 = element("label");
			label3.textContent = "Email kontaktní osoby";
			t10 = space();
			input3 = element("input");
			t11 = space();
			p4 = element("p");
			label4 = element("label");
			label4.textContent = "Telefon kontaktní osoby";
			t13 = space();
			input4 = element("input");
			t14 = space();
			h30 = element("h3");
			h30.textContent = "Fakturační adresa";
			t16 = space();
			p5 = element("p");
			label5 = element("label");
			label5.textContent = "Ulice";
			t18 = space();
			input5 = element("input");
			t19 = space();
			p6 = element("p");
			label6 = element("label");
			label6.textContent = "Město";
			t21 = space();
			input6 = element("input");
			t22 = space();
			p7 = element("p");
			label7 = element("label");
			label7.textContent = "PSČ";
			t24 = space();
			input7 = element("input");
			t25 = space();
			h31 = element("h3");

			h31.innerHTML = `Dodací adresa
    <small>(pokud se liší od fakturační)</small>`;

			t28 = space();
			p8 = element("p");
			label8 = element("label");
			label8.textContent = "Ulice";
			t30 = space();
			input8 = element("input");
			t31 = space();
			p9 = element("p");
			label9 = element("label");
			label9.textContent = "Město";
			t33 = space();
			input9 = element("input");
			t34 = space();
			p10 = element("p");
			label10 = element("label");
			label10.textContent = "PSČ";
			t36 = space();
			input10 = element("input");
			t37 = space();
			p11 = element("p");
			label11 = element("label");
			label11.textContent = "Poznámka";
			t39 = space();
			textarea = element("textarea");
			attr(label0, "for", "company_name");
			attr(input0, "type", "text");
			input0.required = true;
			attr(input0, "id", "company_name");

			input0.value = input0_value_value = /*$orderStore*/ ctx[0].company_name
			? /*$orderStore*/ ctx[0].company_name
			: "";

			attr(label1, "for", "company_id");
			attr(input1, "type", "text");
			input1.required = true;
			attr(input1, "id", "company_id");

			input1.value = input1_value_value = /*$orderStore*/ ctx[0].company_id
			? /*$orderStore*/ ctx[0].company_id
			: "";

			attr(label2, "for", "name_and_surname");
			attr(input2, "type", "text");
			input2.required = true;
			attr(input2, "id", "name_and_surname");

			input2.value = input2_value_value = /*$orderStore*/ ctx[0].name_and_surname
			? /*$orderStore*/ ctx[0].name_and_surname
			: "";

			attr(label3, "for", "email");
			attr(input3, "type", "email");
			input3.required = true;
			attr(input3, "id", "email");

			input3.value = input3_value_value = /*$orderStore*/ ctx[0].email
			? /*$orderStore*/ ctx[0].email
			: "";

			attr(label4, "for", "phone");
			attr(input4, "type", "text");
			input4.required = true;
			attr(input4, "id", "phone");

			input4.value = input4_value_value = /*$orderStore*/ ctx[0].phone
			? /*$orderStore*/ ctx[0].phone
			: "";

			attr(label5, "for", "address_street");
			attr(input5, "type", "text");
			input5.required = true;
			attr(input5, "id", "address_street");

			input5.value = input5_value_value = /*$orderStore*/ ctx[0].address_stree
			? /*$orderStore*/ ctx[0].address_stree
			: "";

			attr(label6, "for", "address_city");
			attr(input6, "type", "text");
			input6.required = true;
			attr(input6, "id", "address_city");

			input6.value = input6_value_value = /*$orderStore*/ ctx[0].address_city
			? /*$orderStore*/ ctx[0].address_city
			: "";

			attr(label7, "for", "address_zip");
			attr(input7, "type", "text");
			input7.required = true;
			attr(input7, "id", "address_zip");

			input7.value = input7_value_value = /*$orderStore*/ ctx[0].address_zip
			? /*$orderStore*/ ctx[0].address_zip
			: "";

			attr(label8, "for", "delivery_address_street");
			attr(input8, "type", "text");
			attr(input8, "id", "delivery_address_street");

			input8.value = input8_value_value = /*$orderStore*/ ctx[0].delivery_address_street
			? /*$orderStore*/ ctx[0].delivery_address_street
			: "";

			attr(label9, "for", "delivery_address_city");
			attr(input9, "type", "text");
			attr(input9, "id", "delivery_address_city");

			input9.value = input9_value_value = /*$orderStore*/ ctx[0].delivery_address_city
			? /*$orderStore*/ ctx[0].delivery_address_city
			: "";

			attr(label10, "for", "delivery_address_zip");
			attr(input10, "type", "text");
			attr(input10, "id", "delivery_address_zip");

			input10.value = input10_value_value = /*$orderStore*/ ctx[0].delivery_address_zip
			? /*$orderStore*/ ctx[0].delivery_address_zip
			: "";

			attr(label11, "for", "order_description");
			attr(textarea, "id", "order_description");

			textarea.value = textarea_value_value = /*$orderStore*/ ctx[0].order_description
			? /*$orderStore*/ ctx[0].order_description
			: "";
		},
		m(target, anchor) {
			insert(target, p0, anchor);
			append(p0, label0);
			append(p0, t1);
			append(p0, input0);
			insert(target, t2, anchor);
			insert(target, p1, anchor);
			append(p1, label1);
			append(p1, t4);
			append(p1, input1);
			insert(target, t5, anchor);
			insert(target, p2, anchor);
			append(p2, label2);
			append(p2, t7);
			append(p2, input2);
			insert(target, t8, anchor);
			insert(target, p3, anchor);
			append(p3, label3);
			append(p3, t10);
			append(p3, input3);
			insert(target, t11, anchor);
			insert(target, p4, anchor);
			append(p4, label4);
			append(p4, t13);
			append(p4, input4);
			insert(target, t14, anchor);
			insert(target, h30, anchor);
			insert(target, t16, anchor);
			insert(target, p5, anchor);
			append(p5, label5);
			append(p5, t18);
			append(p5, input5);
			insert(target, t19, anchor);
			insert(target, p6, anchor);
			append(p6, label6);
			append(p6, t21);
			append(p6, input6);
			insert(target, t22, anchor);
			insert(target, p7, anchor);
			append(p7, label7);
			append(p7, t24);
			append(p7, input7);
			insert(target, t25, anchor);
			insert(target, h31, anchor);
			insert(target, t28, anchor);
			insert(target, p8, anchor);
			append(p8, label8);
			append(p8, t30);
			append(p8, input8);
			insert(target, t31, anchor);
			insert(target, p9, anchor);
			append(p9, label9);
			append(p9, t33);
			append(p9, input9);
			insert(target, t34, anchor);
			insert(target, p10, anchor);
			append(p10, label10);
			append(p10, t36);
			append(p10, input10);
			insert(target, t37, anchor);
			insert(target, p11, anchor);
			append(p11, label11);
			append(p11, t39);
			append(p11, textarea);

			if (!mounted) {
				dispose = [
					listen(input0, "blur", /*blur_handler*/ ctx[2]),
					listen(input1, "blur", /*blur_handler_1*/ ctx[3]),
					listen(input2, "blur", /*blur_handler_2*/ ctx[4]),
					listen(input3, "blur", /*blur_handler_3*/ ctx[5]),
					listen(input4, "blur", /*blur_handler_4*/ ctx[6]),
					listen(input5, "blur", /*blur_handler_5*/ ctx[7]),
					listen(input6, "blur", /*blur_handler_6*/ ctx[8]),
					listen(input7, "blur", /*blur_handler_7*/ ctx[9]),
					listen(input8, "blur", /*blur_handler_8*/ ctx[10]),
					listen(input9, "blur", /*blur_handler_9*/ ctx[11]),
					listen(input10, "blur", /*blur_handler_10*/ ctx[12]),
					listen(textarea, "blur", /*blur_handler_11*/ ctx[13])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*$orderStore*/ 1 && input0_value_value !== (input0_value_value = /*$orderStore*/ ctx[0].company_name
			? /*$orderStore*/ ctx[0].company_name
			: "") && input0.value !== input0_value_value) {
				input0.value = input0_value_value;
			}

			if (dirty & /*$orderStore*/ 1 && input1_value_value !== (input1_value_value = /*$orderStore*/ ctx[0].company_id
			? /*$orderStore*/ ctx[0].company_id
			: "") && input1.value !== input1_value_value) {
				input1.value = input1_value_value;
			}

			if (dirty & /*$orderStore*/ 1 && input2_value_value !== (input2_value_value = /*$orderStore*/ ctx[0].name_and_surname
			? /*$orderStore*/ ctx[0].name_and_surname
			: "") && input2.value !== input2_value_value) {
				input2.value = input2_value_value;
			}

			if (dirty & /*$orderStore*/ 1 && input3_value_value !== (input3_value_value = /*$orderStore*/ ctx[0].email
			? /*$orderStore*/ ctx[0].email
			: "") && input3.value !== input3_value_value) {
				input3.value = input3_value_value;
			}

			if (dirty & /*$orderStore*/ 1 && input4_value_value !== (input4_value_value = /*$orderStore*/ ctx[0].phone
			? /*$orderStore*/ ctx[0].phone
			: "") && input4.value !== input4_value_value) {
				input4.value = input4_value_value;
			}

			if (dirty & /*$orderStore*/ 1 && input5_value_value !== (input5_value_value = /*$orderStore*/ ctx[0].address_stree
			? /*$orderStore*/ ctx[0].address_stree
			: "") && input5.value !== input5_value_value) {
				input5.value = input5_value_value;
			}

			if (dirty & /*$orderStore*/ 1 && input6_value_value !== (input6_value_value = /*$orderStore*/ ctx[0].address_city
			? /*$orderStore*/ ctx[0].address_city
			: "") && input6.value !== input6_value_value) {
				input6.value = input6_value_value;
			}

			if (dirty & /*$orderStore*/ 1 && input7_value_value !== (input7_value_value = /*$orderStore*/ ctx[0].address_zip
			? /*$orderStore*/ ctx[0].address_zip
			: "") && input7.value !== input7_value_value) {
				input7.value = input7_value_value;
			}

			if (dirty & /*$orderStore*/ 1 && input8_value_value !== (input8_value_value = /*$orderStore*/ ctx[0].delivery_address_street
			? /*$orderStore*/ ctx[0].delivery_address_street
			: "") && input8.value !== input8_value_value) {
				input8.value = input8_value_value;
			}

			if (dirty & /*$orderStore*/ 1 && input9_value_value !== (input9_value_value = /*$orderStore*/ ctx[0].delivery_address_city
			? /*$orderStore*/ ctx[0].delivery_address_city
			: "") && input9.value !== input9_value_value) {
				input9.value = input9_value_value;
			}

			if (dirty & /*$orderStore*/ 1 && input10_value_value !== (input10_value_value = /*$orderStore*/ ctx[0].delivery_address_zip
			? /*$orderStore*/ ctx[0].delivery_address_zip
			: "") && input10.value !== input10_value_value) {
				input10.value = input10_value_value;
			}

			if (dirty & /*$orderStore*/ 1 && textarea_value_value !== (textarea_value_value = /*$orderStore*/ ctx[0].order_description
			? /*$orderStore*/ ctx[0].order_description
			: "")) {
				textarea.value = textarea_value_value;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(p0);
			if (detaching) detach(t2);
			if (detaching) detach(p1);
			if (detaching) detach(t5);
			if (detaching) detach(p2);
			if (detaching) detach(t8);
			if (detaching) detach(p3);
			if (detaching) detach(t11);
			if (detaching) detach(p4);
			if (detaching) detach(t14);
			if (detaching) detach(h30);
			if (detaching) detach(t16);
			if (detaching) detach(p5);
			if (detaching) detach(t19);
			if (detaching) detach(p6);
			if (detaching) detach(t22);
			if (detaching) detach(p7);
			if (detaching) detach(t25);
			if (detaching) detach(h31);
			if (detaching) detach(t28);
			if (detaching) detach(p8);
			if (detaching) detach(t31);
			if (detaching) detach(p9);
			if (detaching) detach(t34);
			if (detaching) detach(p10);
			if (detaching) detach(t37);
			if (detaching) detach(p11);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $orderStore;
	component_subscribe($$self, orderStore, $$value => $$invalidate(0, $orderStore = $$value));

	const setValue = (name, value, inputElm) => {
		if (inputElm.checkValidity() && orderStore.setProp(name, value)) {
			inputElm.classList.remove("invalid");
		} else {
			inputElm.classList.add("invalid");
		}
	};

	const blur_handler = e => setValue("company_name", e.target.value, e.target);
	const blur_handler_1 = e => setValue("company_id", e.target.value, e.target);
	const blur_handler_2 = e => setValue("name_and_surname", e.target.value, e.target);
	const blur_handler_3 = e => setValue("email", e.target.value, e.target);
	const blur_handler_4 = e => setValue("phone", e.target.value, e.target);
	const blur_handler_5 = e => setValue("address_street", e.target.value, e.target);
	const blur_handler_6 = e => setValue("address_city", e.target.value, e.target);
	const blur_handler_7 = e => setValue("address_zip", e.target.value, e.target);
	const blur_handler_8 = e => setValue("delivery_address_street", e.target.value, e.target);
	const blur_handler_9 = e => setValue("delivery_address_city", e.target.value, e.target);
	const blur_handler_10 = e => setValue("delivery_address_zip", e.target.value, e.target);
	const blur_handler_11 = e => setValue("order_description", e.target.value, e.target);

	return [
		$orderStore,
		setValue,
		blur_handler,
		blur_handler_1,
		blur_handler_2,
		blur_handler_3,
		blur_handler_4,
		blur_handler_5,
		blur_handler_6,
		blur_handler_7,
		blur_handler_8,
		blur_handler_9,
		blur_handler_10,
		blur_handler_11
	];
}

class OrderCompany extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default OrderCompany;