/* shop/components/Basket.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text
} from "svelte/internal";

import basketStore, {
	basketItemsCount,
	basketPrice,
	basketQuantity,
	basketDiscount,
	isDiscountActive
} from "./../basketStore";

import basketImage from "./../basket.svg";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[7] = list[i];
	return child_ctx;
}

// (99:0) {:else}
function create_else_block(ctx) {
	let p;

	return {
		c() {
			p = element("p");
			p.textContent = "Váš košík je prázdný";
			attr(p, "class", "basketInfoEmpty");
		},
		m(target, anchor) {
			insert(target, p, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (39:0) {#if $basketItemsCount > 0}
function create_if_block(ctx) {
	let div;
	let table;
	let thead;
	let t11;
	let tbody;
	let t12;
	let t13;
	let each_value = /*$basketStore*/ ctx[1].items;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	let if_block0 = /*$basketQuantity*/ ctx[2] < /*$basketStore*/ ctx[1].quantityThreshold && /*$isDiscountActive*/ ctx[3] && /*$basketStore*/ ctx[1].unitDiscount > 0 && create_if_block_2(ctx);
	let if_block1 = /*$basketQuantity*/ ctx[2] >= /*$basketStore*/ ctx[1].quantityThreshold && /*$isDiscountActive*/ ctx[3] && /*$basketStore*/ ctx[1].unitDiscount > 0 && create_if_block_1(ctx);

	return {
		c() {
			div = element("div");
			table = element("table");
			thead = element("thead");

			thead.innerHTML = `<tr><th>Položka</th> 
                <th>Typ</th> 
                <th>Cena</th> 
                <th>Počet</th> 
                <th>Cena celkem</th> 
                <th>Smazat z košíku</th></tr>`;

			t11 = space();
			tbody = element("tbody");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t12 = space();
			if (if_block0) if_block0.c();
			t13 = space();
			if (if_block1) if_block1.c();
			attr(table, "class", "uk-table basketTable ");
			attr(div, "class", "uk-overflow-auto");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, table);
			append(table, thead);
			append(table, t11);
			append(table, tbody);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(tbody, null);
			}

			append(div, t12);
			if (if_block0) if_block0.m(div, null);
			append(div, t13);
			if (if_block1) if_block1.m(div, null);
		},
		p(ctx, dirty) {
			if (dirty & /*basketStore, $basketStore*/ 2) {
				each_value = /*$basketStore*/ ctx[1].items;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(tbody, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (/*$basketQuantity*/ ctx[2] < /*$basketStore*/ ctx[1].quantityThreshold && /*$isDiscountActive*/ ctx[3] && /*$basketStore*/ ctx[1].unitDiscount > 0) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					if_block0.m(div, t13);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*$basketQuantity*/ ctx[2] >= /*$basketStore*/ ctx[1].quantityThreshold && /*$isDiscountActive*/ ctx[3] && /*$basketStore*/ ctx[1].unitDiscount > 0) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					if_block1.m(div, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
		}
	};
}

// (57:24) {#if item.description}
function create_if_block_3(ctx) {
	let br;
	let t0;
	let t1_value = /*item*/ ctx[7].description + "";
	let t1;
	let t2;

	return {
		c() {
			br = element("br");
			t0 = text("(");
			t1 = text(t1_value);
			t2 = text(")");
		},
		m(target, anchor) {
			insert(target, br, anchor);
			insert(target, t0, anchor);
			insert(target, t1, anchor);
			insert(target, t2, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$basketStore*/ 2 && t1_value !== (t1_value = /*item*/ ctx[7].description + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(br);
			if (detaching) detach(t0);
			if (detaching) detach(t1);
			if (detaching) detach(t2);
		}
	};
}

// (53:12) {#each $basketStore.items as item}
function create_each_block(ctx) {
	let tr;
	let td0;
	let strong;
	let t0_value = /*item*/ ctx[7].title + "";
	let t0;
	let t1;
	let t2;
	let td1;

	let t3_value = (/*item*/ ctx[7].book_type == "PRINT"
	? "tištěná"
	: "ekniha") + "";

	let t3;
	let t4;
	let td2;
	let t5_value = /*item*/ ctx[7].unit_price.toLocaleString() + "";
	let t5;
	let t6;
	let t7;
	let td3;
	let input;
	let input_value_value;
	let t8;
	let td4;
	let t9_value = /*item*/ ctx[7].full_price.toLocaleString() + "";
	let t9;
	let t10;
	let t11;
	let td5;
	let button;
	let t13;
	let mounted;
	let dispose;
	let if_block = /*item*/ ctx[7].description && create_if_block_3(ctx);

	function change_handler(...args) {
		return /*change_handler*/ ctx[5](/*item*/ ctx[7], ...args);
	}

	function click_handler() {
		return /*click_handler*/ ctx[6](/*item*/ ctx[7]);
	}

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			strong = element("strong");
			t0 = text(t0_value);
			t1 = space();
			if (if_block) if_block.c();
			t2 = space();
			td1 = element("td");
			t3 = text(t3_value);
			t4 = space();
			td2 = element("td");
			t5 = text(t5_value);
			t6 = text(" Kč");
			t7 = space();
			td3 = element("td");
			input = element("input");
			t8 = space();
			td4 = element("td");
			t9 = text(t9_value);
			t10 = text(" Kč");
			t11 = space();
			td5 = element("td");
			button = element("button");
			button.textContent = "X";
			t13 = space();
			attr(input, "type", "number");
			attr(input, "class", "basketInput");
			input.value = input_value_value = /*item*/ ctx[7].quantity;
			attr(input, "min", "1");
			attr(button, "type", "button");
			attr(button, "class", "basketDeleteItem");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, strong);
			append(strong, t0);
			append(td0, t1);
			if (if_block) if_block.m(td0, null);
			append(tr, t2);
			append(tr, td1);
			append(td1, t3);
			append(tr, t4);
			append(tr, td2);
			append(td2, t5);
			append(td2, t6);
			append(tr, t7);
			append(tr, td3);
			append(td3, input);
			append(tr, t8);
			append(tr, td4);
			append(td4, t9);
			append(td4, t10);
			append(tr, t11);
			append(tr, td5);
			append(td5, button);
			append(tr, t13);

			if (!mounted) {
				dispose = [
					listen(input, "change", change_handler),
					listen(button, "click", click_handler)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*$basketStore*/ 2 && t0_value !== (t0_value = /*item*/ ctx[7].title + "")) set_data(t0, t0_value);

			if (/*item*/ ctx[7].description) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_3(ctx);
					if_block.c();
					if_block.m(td0, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*$basketStore*/ 2 && t3_value !== (t3_value = (/*item*/ ctx[7].book_type == "PRINT"
			? "tištěná"
			: "ekniha") + "")) set_data(t3, t3_value);

			if (dirty & /*$basketStore*/ 2 && t5_value !== (t5_value = /*item*/ ctx[7].unit_price.toLocaleString() + "")) set_data(t5, t5_value);

			if (dirty & /*$basketStore*/ 2 && input_value_value !== (input_value_value = /*item*/ ctx[7].quantity)) {
				input.value = input_value_value;
			}

			if (dirty & /*$basketStore*/ 2 && t9_value !== (t9_value = /*item*/ ctx[7].full_price.toLocaleString() + "")) set_data(t9, t9_value);
		},
		d(detaching) {
			if (detaching) detach(tr);
			if (if_block) if_block.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

// (90:4) {#if $basketQuantity < $basketStore.quantityThreshold && $isDiscountActive && $basketStore.unitDiscount > 0}
function create_if_block_2(ctx) {
	let p;
	let t0;
	let t1_value = /*$basketStore*/ ctx[1].quantityThreshold - /*$basketQuantity*/ ctx[2] + "";
	let t1;
	let t2;
	let t3_value = getBookForm(/*$basketStore*/ ctx[1].quantityThreshold - /*$basketQuantity*/ ctx[2]) + "";
	let t3;
	let t4;
	let b;
	let t5_value = /*$basketStore*/ ctx[1].unitDiscount + "";
	let t5;
	let t6;
	let t7;

	return {
		c() {
			p = element("p");
			t0 = text("Vložte do košíku alespoň ještě ");
			t1 = text(t1_value);
			t2 = space();
			t3 = text(t3_value);
			t4 = text(" pro získání ");
			b = element("b");
			t5 = text(t5_value);
			t6 = text(" Kč slevy");
			t7 = text(" za každou knihu ve Vašem košíku!");
			set_style(b, "color", "red");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t0);
			append(p, t1);
			append(p, t2);
			append(p, t3);
			append(p, t4);
			append(p, b);
			append(b, t5);
			append(b, t6);
			append(p, t7);
		},
		p(ctx, dirty) {
			if (dirty & /*$basketStore, $basketQuantity*/ 6 && t1_value !== (t1_value = /*$basketStore*/ ctx[1].quantityThreshold - /*$basketQuantity*/ ctx[2] + "")) set_data(t1, t1_value);
			if (dirty & /*$basketStore, $basketQuantity*/ 6 && t3_value !== (t3_value = getBookForm(/*$basketStore*/ ctx[1].quantityThreshold - /*$basketQuantity*/ ctx[2]) + "")) set_data(t3, t3_value);
			if (dirty & /*$basketStore*/ 2 && t5_value !== (t5_value = /*$basketStore*/ ctx[1].unitDiscount + "")) set_data(t5, t5_value);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (94:4) {#if $basketQuantity >= $basketStore.quantityThreshold && $isDiscountActive && $basketStore.unitDiscount > 0}
function create_if_block_1(ctx) {
	let p;
	let t0;
	let t1_value = /*$basketStore*/ ctx[1].quantityThreshold + "";
	let t1;
	let t2;
	let t3_value = getBookForm(/*$basketStore*/ ctx[1].quantityThreshold) + "";
	let t3;
	let t4;
	let b;
	let t5;
	let t6;
	let t7;

	return {
		c() {
			p = element("p");
			t0 = text("Hurá! Protože máte v košíku alespoň ");
			t1 = text(t1_value);
			t2 = space();
			t3 = text(t3_value);
			t4 = text(", máte slevu celkem ");
			b = element("b");
			t5 = text(/*$basketDiscount*/ ctx[4]);
			t6 = text(" kč");
			t7 = text("!");
			set_style(b, "color", "red");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t0);
			append(p, t1);
			append(p, t2);
			append(p, t3);
			append(p, t4);
			append(p, b);
			append(b, t5);
			append(b, t6);
			append(p, t7);
		},
		p(ctx, dirty) {
			if (dirty & /*$basketStore*/ 2 && t1_value !== (t1_value = /*$basketStore*/ ctx[1].quantityThreshold + "")) set_data(t1, t1_value);
			if (dirty & /*$basketStore*/ 2 && t3_value !== (t3_value = getBookForm(/*$basketStore*/ ctx[1].quantityThreshold) + "")) set_data(t3, t3_value);
			if (dirty & /*$basketDiscount*/ 16) set_data(t5, /*$basketDiscount*/ ctx[4]);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let h1;
	let img;
	let img_src_value;
	let t0;
	let t1;
	let div0;
	let t3;

	function select_block_type(ctx, dirty) {
		if (/*$basketItemsCount*/ ctx[0] > 0) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			div1 = element("div");
			h1 = element("h1");
			img = element("img");
			t0 = text("\n    Košík");
			t1 = space();
			div0 = element("div");
			div0.textContent = "Všechny ceny jsou vč. DPH";
			t3 = space();
			if_block.c();
			if (img.src !== (img_src_value = basketImage)) attr(img, "src", img_src_value);
			attr(img, "alt", "Košík");
			attr(div0, "class", "basketInfoVat");
			attr(div1, "class", "container basket");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, h1);
			append(h1, img);
			append(h1, t0);
			append(div1, t1);
			append(div1, div0);
			append(div1, t3);
			if_block.m(div1, null);
		},
		p(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div1, null);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
			if_block.d();
		}
	};
}

function getBookForm(number) {
	// Convert to absolute number if negative
	const absNumber = Math.abs(number);

	// Handle special case for 1
	if (absNumber === 1) return "knihu";

	// Handle numbers 2-4
	if (absNumber >= 2 && absNumber <= 4) return "knihy";

	// Handle all other cases (0, 5 and more)
	return "knih";
}

function instance($$self, $$props, $$invalidate) {
	let $basketItemsCount;
	let $basketStore;
	let $basketQuantity;
	let $isDiscountActive;
	let $basketDiscount;
	component_subscribe($$self, basketItemsCount, $$value => $$invalidate(0, $basketItemsCount = $$value));
	component_subscribe($$self, basketStore, $$value => $$invalidate(1, $basketStore = $$value));
	component_subscribe($$self, basketQuantity, $$value => $$invalidate(2, $basketQuantity = $$value));
	component_subscribe($$self, isDiscountActive, $$value => $$invalidate(3, $isDiscountActive = $$value));
	component_subscribe($$self, basketDiscount, $$value => $$invalidate(4, $basketDiscount = $$value));
	const change_handler = (item, e) => basketStore.setProductQuantity(item.book, item.book_type, e.target.value);
	const click_handler = item => basketStore.removeProduct(item.book, item.book_type);

	return [
		$basketItemsCount,
		$basketStore,
		$basketQuantity,
		$isDiscountActive,
		$basketDiscount,
		change_handler,
		click_handler
	];
}

class Basket extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Basket;