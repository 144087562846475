/* shop/AddProduct.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	space,
	text,
	to_number
} from "svelte/internal";

import basketStore from "./basketStore";

function create_if_block_1(ctx) {
	let input;
	let input_checked_value;
	let t0;
	let label;
	let span;
	let t5;
	let t6_value = (/*print*/ ctx[3].in_stock ? "skladem" : "není skladem") + "";
	let t6;
	let mounted;
	let dispose;

	return {
		c() {
			input = element("input");
			t0 = space();
			label = element("label");
			span = element("span");
			span.textContent = `${/*print*/ ctx[3].binding}: ${/*print*/ ctx[3].unit_price} Kč`;
			t5 = space();
			t6 = text(t6_value);
			attr(input, "type", "radio");
			attr(input, "id", "type_print");
			input.checked = input_checked_value = /*selectedType*/ ctx[0] && /*selectedType*/ ctx[0] == /*print*/ ctx[3];
			attr(input, "class", "radok");
			attr(label, "for", "type_print");
		},
		m(target, anchor) {
			insert(target, input, anchor);
			insert(target, t0, anchor);
			insert(target, label, anchor);
			append(label, span);
			append(label, t5);
			append(label, t6);

			if (!mounted) {
				dispose = listen(input, "click", /*click_handler*/ ctx[5]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*selectedType*/ 1 && input_checked_value !== (input_checked_value = /*selectedType*/ ctx[0] && /*selectedType*/ ctx[0] == /*print*/ ctx[3])) {
				input.checked = input_checked_value;
			}
		},
		d(detaching) {
			if (detaching) detach(input);
			if (detaching) detach(t0);
			if (detaching) detach(label);
			mounted = false;
			dispose();
		}
	};
}

// (46:0) {#if ebook}
function create_if_block(ctx) {
	let input;
	let input_checked_value;
	let t0;
	let label;
	let span;
	let mounted;
	let dispose;

	return {
		c() {
			input = element("input");
			t0 = space();
			label = element("label");
			span = element("span");
			span.textContent = `e-kniha: ${/*ebook*/ ctx[4].unit_price} Kč`;
			attr(input, "type", "radio");
			attr(input, "id", "type_ebook");
			input.checked = input_checked_value = /*selectedType*/ ctx[0] && /*selectedType*/ ctx[0] == /*ebook*/ ctx[4];
			attr(input, "class", "radok");
			attr(label, "for", "type_ebook");
		},
		m(target, anchor) {
			insert(target, input, anchor);
			insert(target, t0, anchor);
			insert(target, label, anchor);
			append(label, span);

			if (!mounted) {
				dispose = listen(input, "click", /*click_handler_1*/ ctx[6]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*selectedType*/ 1 && input_checked_value !== (input_checked_value = /*selectedType*/ ctx[0] && /*selectedType*/ ctx[0] == /*ebook*/ ctx[4])) {
				input.checked = input_checked_value;
			}
		},
		d(detaching) {
			if (detaching) detach(input);
			if (detaching) detach(t0);
			if (detaching) detach(label);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let t0;
	let t1;
	let div2;
	let div0;
	let t2_value = /*price*/ ctx[2].toLocaleString() + "";
	let t2;
	let t3;
	let t4;
	let div1;
	let input;
	let t5;
	let button;
	let mounted;
	let dispose;
	let if_block0 = /*print*/ ctx[3] && create_if_block_1(ctx);
	let if_block1 = /*ebook*/ ctx[4] && create_if_block(ctx);

	return {
		c() {
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			div2 = element("div");
			div0 = element("div");
			t2 = text(t2_value);
			t3 = text(" Kč");
			t4 = space();
			div1 = element("div");
			input = element("input");
			t5 = space();
			button = element("button");
			button.textContent = "do košíku";
			attr(div0, "class", "book-shope--addToCart--price");
			attr(input, "type", "number");
			attr(input, "min", "1");
			attr(button, "type", "button");
			attr(div1, "class", "book-shope--addToCart--addButton");
			attr(div2, "class", "book-shope--addToCart");
		},
		m(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t0, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, t1, anchor);
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, t2);
			append(div0, t3);
			append(div2, t4);
			append(div2, div1);
			append(div1, input);
			set_input_value(input, /*quantity*/ ctx[1]);
			append(div1, t5);
			append(div1, button);

			if (!mounted) {
				dispose = [
					listen(input, "input", /*input_input_handler*/ ctx[7]),
					listen(button, "click", /*click_handler_2*/ ctx[8])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*print*/ ctx[3]) if_block0.p(ctx, dirty);
			if (/*ebook*/ ctx[4]) if_block1.p(ctx, dirty);
			if (dirty & /*price*/ 4 && t2_value !== (t2_value = /*price*/ ctx[2].toLocaleString() + "")) set_data(t2, t2_value);

			if (dirty & /*quantity*/ 2 && to_number(input.value) !== /*quantity*/ ctx[1]) {
				set_input_value(input, /*quantity*/ ctx[1]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t0);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(t1);
			if (detaching) detach(div2);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const dataElm = document.getElementById("addProduct");
	const data = JSON.parse(dataElm.dataset.addProduct);

	// jednotlive typy knihy
	const print = data.print ? data.print : null;

	const ebook = data.ebook ? data.ebook : null;

	// nastavitelne parametry
	let selectedType = print ? print : ebook;

	let quantity = 1;
	let price = 0;
	const click_handler = () => $$invalidate(0, selectedType = print);
	const click_handler_1 = () => $$invalidate(0, selectedType = ebook);

	function input_input_handler() {
		quantity = to_number(this.value);
		$$invalidate(1, quantity);
	}

	const click_handler_2 = () => basketStore.insertProduct(selectedType, quantity);

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*quantity, selectedType*/ 3) {
			// vypocet ceny
			$: $$invalidate(2, price = quantity * selectedType.unit_price);
		}
	};

	return [
		selectedType,
		quantity,
		price,
		print,
		ebook,
		click_handler,
		click_handler_1,
		input_input_handler,
		click_handler_2
	];
}

class AddProduct extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default AddProduct;