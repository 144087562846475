/* shop/OrderEbookDownloader.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	space,
	text
} from "svelte/internal";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[9] = list[i];
	return child_ctx;
}

// (62:0) {#if display_state == DISPLAY_STATES.LOCKED}
function create_if_block_4(ctx) {
	let h1;
	let t1;
	let label;
	let t3;
	let input;
	let t4;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Váš ebook ke stažení";
			t1 = space();
			label = element("label");
			label.textContent = "Zde prosím zadejte váš kod zaslaný emailem:";
			t3 = space();
			input = element("input");
			t4 = space();
			button = element("button");
			button.textContent = "Odemknout stažení eknih";
			attr(label, "class", "labelCenter");
			attr(label, "for", "code_for_order");
			attr(input, "id", "code_for_order");
			attr(input, "type", "password");
			attr(input, "class", "basketInputCode");
			attr(input, "placeholder", "Váš kód z emailu");
			attr(button, "class", "basketButton");
			attr(button, "type", "button");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, label, anchor);
			insert(target, t3, anchor);
			insert(target, input, anchor);
			set_input_value(input, /*password*/ ctx[0]);
			insert(target, t4, anchor);
			insert(target, button, anchor);

			if (!mounted) {
				dispose = [
					listen(input, "input", /*input_input_handler*/ ctx[5]),
					listen(button, "click", /*click_handler*/ ctx[6])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*password*/ 1 && input.value !== /*password*/ ctx[0]) {
				set_input_value(input, /*password*/ ctx[0]);
			}
		},
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(label);
			if (detaching) detach(t3);
			if (detaching) detach(input);
			if (detaching) detach(t4);
			if (detaching) detach(button);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (81:0) {#if display_state == DISPLAY_STATES.UNLOCKED}
function create_if_block(ctx) {
	let each_1_anchor;
	let each_value = /*ebooks*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*ebooks*/ 2) {
				each_value = /*ebooks*/ ctx[1];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

// (89:16) {#if ebook.ebook_pdf}
function create_if_block_3(ctx) {
	let li;
	let a;
	let t;
	let a_href_value;

	return {
		c() {
			li = element("li");
			a = element("a");
			t = text("PDF");
			attr(a, "href", a_href_value = /*ebook*/ ctx[9].ebook_pdf);
			attr(a, "class", "btn-link-left");
			attr(a, "target", "_blank");
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, a);
			append(a, t);
		},
		p(ctx, dirty) {
			if (dirty & /*ebooks*/ 2 && a_href_value !== (a_href_value = /*ebook*/ ctx[9].ebook_pdf)) {
				attr(a, "href", a_href_value);
			}
		},
		d(detaching) {
			if (detaching) detach(li);
		}
	};
}

// (95:16) {#if ebook.ebook_epub}
function create_if_block_2(ctx) {
	let li;
	let a;
	let t;
	let a_href_value;

	return {
		c() {
			li = element("li");
			a = element("a");
			t = text("EPUB");
			attr(a, "href", a_href_value = /*ebook*/ ctx[9].ebook_epub);
			attr(a, "class", "btn-link-left");
			attr(a, "target", "_blank");
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, a);
			append(a, t);
		},
		p(ctx, dirty) {
			if (dirty & /*ebooks*/ 2 && a_href_value !== (a_href_value = /*ebook*/ ctx[9].ebook_epub)) {
				attr(a, "href", a_href_value);
			}
		},
		d(detaching) {
			if (detaching) detach(li);
		}
	};
}

// (101:16) {#if ebook.ebook_mobi}
function create_if_block_1(ctx) {
	let li;
	let a;
	let t;
	let a_href_value;

	return {
		c() {
			li = element("li");
			a = element("a");
			t = text("MOBI");
			attr(a, "href", a_href_value = /*ebook*/ ctx[9].ebook_mobi);
			attr(a, "class", "btn-link-left");
			attr(a, "target", "_blank");
		},
		m(target, anchor) {
			insert(target, li, anchor);
			append(li, a);
			append(a, t);
		},
		p(ctx, dirty) {
			if (dirty & /*ebooks*/ 2 && a_href_value !== (a_href_value = /*ebook*/ ctx[9].ebook_mobi)) {
				attr(a, "href", a_href_value);
			}
		},
		d(detaching) {
			if (detaching) detach(li);
		}
	};
}

// (82:4) {#each ebooks as ebook}
function create_each_block(ctx) {
	let div;
	let h2;
	let t1;
	let h1;
	let t2_value = /*ebook*/ ctx[9].title + "";
	let t2;
	let t3;
	let br;
	let t4;
	let p;
	let t6;
	let ul;
	let t7;
	let t8;
	let t9;
	let if_block0 = /*ebook*/ ctx[9].ebook_pdf && create_if_block_3(ctx);
	let if_block1 = /*ebook*/ ctx[9].ebook_epub && create_if_block_2(ctx);
	let if_block2 = /*ebook*/ ctx[9].ebook_mobi && create_if_block_1(ctx);

	return {
		c() {
			div = element("div");
			h2 = element("h2");
			h2.textContent = "Portál pro stažení:";
			t1 = space();
			h1 = element("h1");
			t2 = text(t2_value);
			t3 = space();
			br = element("br");
			t4 = space();
			p = element("p");
			p.textContent = "Formáty ke stažení:";
			t6 = space();
			ul = element("ul");
			if (if_block0) if_block0.c();
			t7 = space();
			if (if_block1) if_block1.c();
			t8 = space();
			if (if_block2) if_block2.c();
			t9 = space();
			attr(ul, "class", "order__list tabs__content__links");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h2);
			append(div, t1);
			append(div, h1);
			append(h1, t2);
			append(div, t3);
			append(div, br);
			append(div, t4);
			append(div, p);
			append(div, t6);
			append(div, ul);
			if (if_block0) if_block0.m(ul, null);
			append(ul, t7);
			if (if_block1) if_block1.m(ul, null);
			append(ul, t8);
			if (if_block2) if_block2.m(ul, null);
			append(div, t9);
		},
		p(ctx, dirty) {
			if (dirty & /*ebooks*/ 2 && t2_value !== (t2_value = /*ebook*/ ctx[9].title + "")) set_data(t2, t2_value);

			if (/*ebook*/ ctx[9].ebook_pdf) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					if_block0.m(ul, t7);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*ebook*/ ctx[9].ebook_epub) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					if_block1.m(ul, t8);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*ebook*/ ctx[9].ebook_mobi) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_1(ctx);
					if_block2.c();
					if_block2.m(ul, null);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
		}
	};
}

function create_fragment(ctx) {
	let div;
	let t;
	let if_block0 = /*display_state*/ ctx[2] == /*DISPLAY_STATES*/ ctx[3].LOCKED && create_if_block_4(ctx);
	let if_block1 = /*display_state*/ ctx[2] == /*DISPLAY_STATES*/ ctx[3].UNLOCKED && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block0) if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			attr(div, "class", "container basket order");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block0) if_block0.m(div, null);
			append(div, t);
			if (if_block1) if_block1.m(div, null);
		},
		p(ctx, [dirty]) {
			if (/*display_state*/ ctx[2] == /*DISPLAY_STATES*/ ctx[3].LOCKED) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_4(ctx);
					if_block0.c();
					if_block0.m(div, t);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*display_state*/ ctx[2] == /*DISPLAY_STATES*/ ctx[3].UNLOCKED) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(div, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const dataElm = document.getElementById("orderEbookDownloader");
	const orderId = dataElm.dataset.order;

	// password
	let password = "";

	// ebooks
	let ebooks = [];

	// stav zobrazeni
	const DISPLAY_STATES = { LOCKED: "LOCKED", UNLOCKED: "UNLOCKED" };

	let display_state = DISPLAY_STATES.LOCKED;

	// odeslani hesla
	const handleUnlockDownload = () => {
		const ERROR_MESSAGE = "Pravděpodobně jste zadal/a chybné heslo.";
		const URL = "/api/shop/order/" + orderId + "/ebook-items/" + password;
		const xhr = new XMLHttpRequest();
		xhr.open("GET", URL, true);

		// chyba pri odeslani
		xhr.addEventListener("error", () => {
			alert(ERROR_MESSAGE);
		});

		// zpracovani response
		xhr.addEventListener("load", () => {
			// v poradku
			if (xhr.status == 200) {
				$$invalidate(2, display_state = DISPLAY_STATES.UNLOCKED);
				$$invalidate(1, ebooks = JSON.parse(xhr.responseText).ebooks);
			} else {
				alert(ERROR_MESSAGE); // chyba
			}
		});

		// odeslani pozadavku
		xhr.send();
	};

	function input_input_handler() {
		password = this.value;
		$$invalidate(0, password);
	}

	const click_handler = () => handleUnlockDownload();

	return [
		password,
		ebooks,
		display_state,
		DISPLAY_STATES,
		handleUnlockDownload,
		input_input_handler,
		click_handler
	];
}

class OrderEbookDownloader extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default OrderEbookDownloader;